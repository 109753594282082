<!-- BODY LAYOUT -->
<template>
  <!-- Centers and horizontally pads the content. Use fluid to fully extend the container -->
  <v-container>
    <v-row>
      <!-- IMAGE ROW -->
      <v-col sm="12" class="py-8">
        <v-img
          :src="require('@assets/ag-horz.png')"
          contain
          height="180"
        ></v-img>
      </v-col>
      <!-- WELCOME TEXT -->
      <v-col sm="12" class="text-center">
        <h1 class="display-1 mb-5 mt-4"
          >Welcome to the Agriculture Licensing Website</h1
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" class="text-center">
        <p class="subtitle-1"
          >This site is here to provide you with the means to apply and renew
          any of your Technical Services licenses.</p
        >

        <p v-if="!isLoggedIn">
          If you have a previous account, please login
          <router-link to="/login">here</router-link>. If you don't have an
          account, please create a username and password
          <router-link to="/Register">here</router-link>.
        </p>
        <p>
          For assistance, please call (307) 777-7324 or email us at
          <a :href="`mailto:${email}`">
            {{ email }}
          </a>
          <!-- <router-link to="mailto:agrtshelpdesk@wyo.gov"
            >agrtshelpdesk@wyo.gov</router-link
          > -->
        </p>
        <p>
          Wyoming Department of Agriculture
          <br />2219 Carey Ave <br />Cheyenne, WY 82002
        </p>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col sm="12" class="text-center">
        <p>[WELCOME/EXPLANATORY TEXT ON SITE USE After Register]</p>
        <p class="subtitle-1"
          >Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto
          ut tempore voluptas harum laborum iste aliquid eos ab reprehenderit,
          beatae molestias. Minus ducimus voluptatum sunt quis repellat tempore
          consequatur nesciunt.</p
        >

        <p
          >To be able to apply for a license an account with the Department of
          Agriculture has to be
          <router-link to="/accountCreation">created</router-link></p
        >
        <p>[Account creation can also be accessed from side navigation]</p>
      </v-col>
    </v-row>-->
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import { authMethods, authComputed } from '@state/helpers'
export default {
  data: () => ({
    email: 'agrtshelpdesk@wyo.gov',
  }),
  computed: {
    ...authComputed,
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
@import '@design';

.shellStyles {
  font-family: $system-default-font-family;
  h1,
  h2,
  h3 {
    color: $color-heading-text;
  }
}
</style>
